
// Components
import VGoalCard from '../components/VGoalCard.vue';
import VTaskCard from '../components/VTaskCard.vue';
import VPage from '../components/VPage.vue';
import VPageHeader from '../components/VPageHeader.vue';
import VPageContent from '../components/VPageContent.vue';
import VTaskFilter, { TaskFilterTypes } from '../components/VTaskFilter.vue';
import VSearchField from '../components/VSearchField.vue';
import VBoard from '@/components/VBoard.vue';
import VColumn from '../components/VColumn.vue';

// Other
import { setPageTitle } from '@/utils/document-utils';
import store from '@/store';
import IUser from '@/core/Models/IUser';
import TaskService, { QueryTaskRequest } from '@/core/Services/TaskService';
import ITask from '@/core/Models/ITask';
import emitter from '@/core/Emitter';
import { EventNames } from '@/core/EventNames';
import scrollIntoView from 'scroll-into-view';
import IProject from '@/core/Models/IProject';
import UserMapper from '@/core/UserMapper';
import TaskType from '@/core/Values/TaskType';
import IColumn from '@/core/Models/IColumn';
import { Raw, markRaw } from 'vue';
import IMutatorContext from '@/core/Mutations/IMutatorContext';
import { TaskMutatorContext } from '@/core/Mutators/TaskMutator';
import MutationBus from '@/core/Mutations/MutationBus';
import ProjectBoardType from '@/core/Values/ProjectBoardType';
import orderBy from 'lodash.orderby';
import { defineComponent } from 'vue';
import Storages from '@/core/Storages';
import Settings from '@/core/Settings';
import ObjectStorageMapper from '@/core/ObjectStorageMapper';

export default defineComponent({
    components: {
        VGoalCard,
        VTaskCard,
        VBoard,
        VColumn,
        VPage,
        VPageHeader,
        VPageContent,
        VTaskFilter,
        VSearchField,
    },
    data: () => ({
        pageTitle: '',
        searchString: '',
        tasks: [] as ITask[],
        mutatorContext: null as Raw<IMutatorContext> | null,

        TaskFilterTypes: markRaw(TaskFilterTypes),
    }),
    computed: {
        filters: Storages.Filters.computed(Settings.UI.Filters + '.project.view', { value: [], formattedValue: {} }),

        projectId(): number {
            return parseInt(this.$route.params.projectId as string, 10);
        },

        currentUser(): IUser | null {
            return store.state.user;
        },

        project(): IProject | undefined {
            return store.state.projects?.find((project) => project.id == this.projectId);
        },

        columns(): IColumn[] {
            let columns: IColumn[] = [];

            if (!this.project) {
                return columns;
            }

            if (!store.state.boards?.length) {
                return columns;
            }

            const projectTitle = this.$tryTranslate(this.project.title);
            columns = store.state.boards
                .filter((board) => board.projectId === this.project?.id)
                .map((board): IColumn => {
                    const boardTitle = this.$tryTranslate(board.title);

                    const title = boardTitle + ', ' + projectTitle;
                    return {
                        id: -1,
                        title: title,
                        order: 100000 - (board?.type ?? 0),
                        board,
                        boardId: board.id,
                        project: this.project,
                        projectId: board.projectId,
                    };
                });

            return orderBy(columns, (column) => column.order + (column.orderStr ?? ''));
        },

        tasksFilters(): QueryTaskRequest {
            return {
                ...this.filters.formattedValue,
                whereType: [TaskType.Task, TaskType.Goal],
                whereProjectId: [this.project?.id as number],
                includes: ['stories', 'approvements', 'collaborators'],
                search: this.searchString,
            };
        },
    },
    methods: {
        getTasks(column: IColumn): ITask[] {
            if (!this.tasks?.length) {
                return [];
            }

            return this.tasks.filter((task) => task.boardId === column.boardId) ?? [];
        },

        isGoalsColumn(column: IColumn): boolean {
            return column.board?.type === ProjectBoardType.Goals;
        },

        async fetchData(): Promise<void> {
            if (!this.project) {
                return;
            }

            this.tasks = await TaskService.queryAsync(this.tasksFilters);
            this.pageTitle = this.$tryTranslate(this.project.title);

            // Attaching of users and preview to tasks.
            UserMapper.mapTasksAsync(this.tasks);
            ObjectStorageMapper.mapTasksAsync(this.tasks);

            // Deactivate old context, to avoid memory leaks.
            MutationBus.deactivate(this.mutatorContext);
            this.mutatorContext = markRaw(
                new TaskMutatorContext(this.tasks, {
                    mapUsers: true,
                    mapPreview: true,
                    // Fetches a task if the patch contains the changes that can affect of the filters.
                    fetchTask: async (patch: Partial<ITask>) => {
                        if (!TaskService.matchPatch(patch, this.currentUser?.id as string, this.tasksFilters)) {
                            return undefined;
                        }

                        const tasks = await TaskService.queryAsync({
                            ...this.tasksFilters,
                            whereId: [patch.id as number],
                            perPage: 1,
                        });

                        if (!tasks.length) {
                            return undefined;
                        }

                        return UserMapper.mapTaskAsync(tasks[0]);
                    },

                    // Excludes tasks if they are not matching the specified filters.
                    excludeTask: (task: ITask) =>
                        !TaskService.match(task, this.currentUser?.id as string, this.tasksFilters),
                    // Ignores tasks creation if they are not matching the specified filters.
                    ignoreTaskCreating: (task: ITask) =>
                        TaskService.match(task, this.currentUser?.id as string, this.tasksFilters),
                }),
            );
            MutationBus.activate(this.mutatorContext);

            setPageTitle(this.pageTitle);
        },

        TaskPanelBeforeOpen(event: { taskId: number; clientWidth: number }): void {
            this.$nextTick(() => {
                const taskElement = document.querySelector(`.task-card[task-id='${event.taskId}']`) as HTMLElement;
                if (taskElement) {
                    scrollIntoView(taskElement, {
                        time: 250,
                    });
                }
            });
        },
    },

    watch: {
        projectId() {
            this.fetchData();
        },
    },

    created(): void {
        this.fetchData();

        emitter.on(EventNames.TaskPanelBeforeOpen, this.TaskPanelBeforeOpen);
        emitter.on(EventNames.ConnectionLoopReconnected, this.fetchData);
    },

    beforeUnmount(): void {
        // Deactivate old context, to avoid memory leaks.
        MutationBus.deactivate(this.mutatorContext);

        emitter.off(EventNames.TaskPanelBeforeOpen, this.TaskPanelBeforeOpen);
        emitter.off(EventNames.ConnectionLoopReconnected, this.fetchData);
    },
});

import ITask from './Models/ITask';
import IStory from './Models/IStory';
import ObjectStorage from './Services/ObjectStorage';
import IRequestObjectDownloadUri from './Values/IRequestObjectDownloadUri';

interface IObjectStorageStore {
    get(objectName: string): string | undefined;
    set(objectName: string, downloadUri: string): void;
    has(objectName: string): boolean;
    fetchAsync(requests: IRequestObjectDownloadUri[]): Promise<void>;
}

class ObjectStorageMapper {
    _store: IObjectStorageStore;

    public constructor(store: IObjectStorageStore) {
        this._store = store;
    }

    async mapStoryAsync(story: IStory): Promise<IStory> {
        if (!story.objectName) {
            return story;
        }

        await this._store.fetchAsync([
            {
                objectName: story.objectName,
                downloadName: story.fileName,
            },
        ]);

        story.downloadUri = this._store.get(story.objectName);

        return story;
    }

    async mapTaskAsync(task: ITask): Promise<ITask> {
        const objectNames = this._getObjectNamesFromTask(task);

        await this._store.fetchAsync(objectNames);

        return this._mapTasksWithDownloadUri([task])[0];
    }

    async mapTasksAsync(tasks: ITask[]): Promise<ITask[]> {
        const objectNames = tasks.reduce((arr, task) => {
            return [...arr, ...this._getObjectNamesFromTask(task)];
        }, [] as IRequestObjectDownloadUri[]);

        await this._store.fetchAsync(objectNames);

        return this._mapTasksWithDownloadUri(tasks);
    }

    private _mapTasksWithDownloadUri(tasks: ITask[]): ITask[] {
        for (const task of tasks) {
            if (task.previewObjectName) {
                task.previewDownloadUri = this._store.get(task.previewObjectName);
            }

            task.stories?.forEach((story) => {
                if (!story.objectName) {
                    return;
                }
                story.downloadUri = this._store.get(story.objectName);
            });
        }

        return tasks;
    }

    private _getObjectNamesFromTask(task: ITask): IRequestObjectDownloadUri[] {
        const result: IRequestObjectDownloadUri[] = [];

        if (task.previewObjectName) {
            result.push({
                objectName: task.previewObjectName,
                downloadName: task.previewObjectName,
            });
        }

        if (!task.stories) {
            return result;
        }

        const objectNames = task.stories.reduce((carry, story) => {
            if (!story.objectName) {
                return carry;
            }

            carry.push({
                objectName: story.objectName,
                downloadName: story.fileName,
            });

            return carry;
        }, [] as IRequestObjectDownloadUri[]);

        return objectNames;
    }
}

class DefaultObjectStorageStore implements IObjectStorageStore {
    private _map = new Map<string, string>();

    get(objectName: string): string | undefined {
        return this._map.get(objectName);
    }

    has(objectName: string): boolean {
        return this._map.has(objectName);
    }

    set(objectName: string, downloadUri: string): void {
        this._map.set(objectName, downloadUri);
    }

    async fetchAsync(objects: IRequestObjectDownloadUri[]): Promise<void> {
        objects = objects.filter((object) => !this.has(object.objectName));

        if (objects.length === 0) {
            return;
        }

        const result = await ObjectStorage.getDownloadUris(objects);

        for (const object of result) {
            this.set(object.objectName, object.downloadUri);
        }
    }
}

export const store = new DefaultObjectStorageStore();
export const mapper = new ObjectStorageMapper(store);

export default mapper;
